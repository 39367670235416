// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import AddCategory from "./pages/AddCategory";
// import Login from "./pages/Login";
// import DashboardHome from "./pages/DashboardHome";
// import ShowCategory from "./pages/ShowCategory";

// const App = () => {
//   const [categories, setCategories] = useState([]);
// console.log(categories,"categories")
//   useEffect(() => {
//     const savedCategories = localStorage.getItem("categories");
//     if (savedCategories) {
//       setCategories(JSON.parse(savedCategories));
//     } else {
//       setCategories(['Popular']); 
//     }
//   }, []);

//   const updateCategories = (newCategories) => {
//     setCategories(newCategories);
//     localStorage.setItem("categories", JSON.stringify(newCategories)); 
//   };

//   return (
//     <div className="">
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login/>} />
//           <Route path="/" element={<DashboardHome categories={categories} />} />
//           <Route
//             path="/add-category" element={<AddCategory categories={categories} setCategories={updateCategories} />}
//           />
//           <Route path="/showcategory" element={<ShowCategory categories={categories}/>}/>
//         </Routes>
//       </Router>
//     </div>
//   );
// };

// export default App;



// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import AddCategory from "./pages/AddCategory";
// import Login from "./pages/Login";
// import DashboardHome from "./pages/DashboardHome";
// import ShowCategory from "./pages/ShowCategory";
// import axios from "axios"; 

// const App = () => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     const savedCategories = localStorage.getItem("categories");

//     if (savedCategories) {
//       setCategories(JSON.parse(savedCategories));
//     } else {
//       // Fetch categories from the API if not in localStorage
//       axios
//         .get("https://imageuploader.visiontrek.io/getupload-img/fetch-categories")
//         .then((response) => {
//           const apiCategories = response.data.map((item) => item.category);
//           setCategories(apiCategories);
//           localStorage.setItem("categories", JSON.stringify(apiCategories)); 
//         })
//         .catch((error) => {
//           console.error("Error fetching categories:", error);
//           setCategories(["Popular"]); 
//         });
//     }
//   }, []);

//   const updateCategories = (newCategories) => {
//     setCategories(newCategories);
//     localStorage.setItem("categories", JSON.stringify(newCategories));
//   };

//   return (
//     <div>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/" element={<DashboardHome categories={categories} />} />
//           <Route
//             path="/add-category"
//             element={
//               <AddCategory categories={categories} setCategories={updateCategories} />
//             }
//           />
//           <Route path="/showcategory" element={<ShowCategory categories={categories} />} />
//         </Routes>
//       </Router>
//     </div>
//   );
// };

// export default App;



import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AddCategory from "./pages/AddCategory";
import Login from "./pages/Login";
import DashboardHome from "./pages/DashboardHome";
import ShowCategory from "./pages/ShowCategory";
import axios from "axios"; 

const App = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://imageuploader.visiontrek.io/getupload-img/fetch-categories"
        );
        const apiCategories = response.data.map((item) => item.category);
  
        // Save API categories to localStorage for future use
        localStorage.setItem("categories", JSON.stringify(apiCategories));
        setCategories(apiCategories);
      } catch (error) {
        console.error("Error fetching categories from API:", error);
  
        // If API fails, fallback to localStorage categories
        const savedCategories = localStorage.getItem("categories");
        if (savedCategories) {
          setCategories(JSON.parse(savedCategories));
        } else {
          setCategories(["Popular"]); // Default fallback
        }
      }
    };
  
    fetchCategories();
  }, []);
  

  const updateCategories = (newCategories) => {
    setCategories(newCategories);
    localStorage.setItem("categories", JSON.stringify(newCategories));
  };

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<DashboardHome categories={categories} />} />
          <Route
            path="/add-category"
            element={
              <AddCategory categories={categories} setCategories={updateCategories} />
            }
          />
          <Route path="/showcategory" element={<ShowCategory categories={categories} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
